// Scrollbar
// -----------------------------------------

/* Hide scrollbar for Chrome, Safari and Opera */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.no-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

// Remove style from input for copy
.copy-input {
  display: block;
  width: 100%;
  overflow: hidden;
  font-family: var(--default-font-monospace);
  text-overflow: ellipsis;
  white-space: nowrap;
  appearance: none;
  background-color: transparent;
  border: 0;
  outline: 0;
}
