/* stylelint-disable no-invalid-position-at-import-rule */

// Global CSS
// ----------------------------------------------------------------------------

// Ionic
// -------------------------------
@import "@ionic/angular/css/core.css";
@import "@ionic/angular/css/normalize.css";
@import "@ionic/angular/css/structure.css";

// Tailwind
// -------------------------------
@tailwind base;
@tailwind components;
@tailwind utilities;

// BKKR Core
// required for BKKR components to work properly
// -------------------------------
@import "@bkkr/angular/styles/bkkr.css";

// Shared config module
// -------------------------------
@import "ui-module";

// Theme
// -------------------------------
@import "theme";
