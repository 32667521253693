@import "swiper/css/pagination";

:root {
  --swiper-theme-color: var(--ion-color-primary);
  --swiper-pagination-color: var(--ion-color-primary);
  --swiper-pagination-left: auto;
  --swiper-pagination-right: 8px;
  --swiper-pagination-bottom: 8px;
  --swiper-pagination-top: auto;
  --swiper-pagination-fraction-color: inherit;
  --swiper-pagination-progressbar-bg-color: rgba(var(--ion-text-color-rgb, 0, 0, 0), 0.25);
  --swiper-pagination-progressbar-size: 4px;
  --swiper-pagination-bullet-size: 8px;
  --swiper-pagination-bullet-width: 8px;
  --swiper-pagination-bullet-height: 8px;
  --swiper-pagination-bullet-border-radius: 50%;
  --swiper-pagination-bullet-inactive-color: var(--ion-color-step-200, #ccc);
  --swiper-pagination-bullet-inactive-opacity: 0.2;
  --swiper-pagination-bullet-opacity: 1;
  --swiper-pagination-bullet-horizontal-gap: 4px;
  --swiper-pagination-bullet-vertical-gap: 6px;
}

swiper-slide::part(preloader) {
  border-right-color: var(--preloader-color, var(--ion-color-primary));
  border-bottom-color: var(--preloader-color, var(--ion-color-primary));
  border-left-color: var(--preloader-color, var(--ion-color-primary));
}
