ion-split-pane {
  --side-width: 358px;
  --side-min-width: var(--side-width, 0);

  .menu-content {
    transition: filter 0.3s var(--ease-cubic);
  }

  .menu-content-reveal {
    box-shadow: none !important;
  }

  .menu-content.menu-content-open {
    filter: invert(0.05);
    transition-duration: 0.5s;
  }
}

ion-menu {
  --box-shadow: none;

  transition: width 0.5s var(--ease-cubic);
  will-change: width;

  &::part(container) {
    row-gap: 0.5rem;
    padding-left: 72px;
  }
}

// Reduced motion
@media (prefers-reduced-motion: reduce) {
  ion-menu {
    transition: none;
  }
}
