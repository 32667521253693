.thread-item {
  position: relative;
  padding-left: 36px;

  &::before {
    position: absolute;
    top: 0;
    left: 10px;
    width: 16px;
    height: 20px;
    content: '';
    border-color: var(--ion-color-step-150);
    border-style: solid;
    border-width: 0 0 3px 3px;
    border-bottom-left-radius: 10px;
    transform: translateY(-0.3em);
  }

  &:not(:last-of-type)::after {
    position: absolute;
    top: 0;
    left: 10px;
    width: 20px;
    height: 100%;
    content: '';
    border-color: var(--ion-color-step-150);
    border-style: solid;
    border-width: 0 0 0 3px;
  }
}
