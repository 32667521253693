@use "sass:map";
@use "ui-mixins" as mixins;
@use "ui-variables" as variables;

// Basic color variables
// -----------------------------------------
:root {
  // Generates the color variables based on the
  // colors map
  @each $color-name, $value in variables.$colors {
    @include mixins.generate-color(variables.$colors, $color-name);
  }

  @each $color-name, $value in variables.$brands {
    @include mixins.generate-color(variables.$brands, $color-name);
  }

  // Static colors
  --bkkr-color-black: #000;
  --bkkr-color-white: #fff;
  --ion-color-black: #000;
  --ion-color-white: #fff;
}

// Custom color classes
// -----------------------------------------
// Generates the text color modifier classes based
// on the colors map

.bkkr-color-info {
  --bkkr-color-base: var(--bkkr-color-info, #067bc2);
  --bkkr-color-base-rgb: var(--bkkr-color-info-rgb, 6, 123, 194);
  --bkkr-color-contrast: var(--bkkr-color-info-contrast, #fff);
  --bkkr-color-contrast-rgb: var(--bkkr-color-info-contrast-rgb, 255, 255, 255);
  --bkkr-color-shade: var(--bkkr-color-info-shade, #056aa8);
  --bkkr-color-shade-rgb: var(--bkkr-color-info-shade-rgb, 5, 106, 168);
  --bkkr-color-tint: var(--bkkr-color-info-tint, #078adb);
  --bkkr-color-tint-rgb: var(--bkkr-color-info-tint-rgb, 7, 138, 219);
}

@each $color-name, $value in variables.$brands {
  @include mixins.classes(variables.$brands, $color-name);
}
