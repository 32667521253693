.ngx-charts {
  text,
  .tooltip-anchor {
    fill: var(--color) !important;
  }

  .x.axis {
    .ng-star-inserted:not(.tick, [ngx-charts-x-axis-ticks]) {
      display: none;
    }
  }

  .gridline-path {
    stroke: var(--ion-color-step-350) !important;
  }

  .grid-panel .gridpanel {
    fill: none !important;
  }
}
