// Accordion
ion-accordion {
  background: transparent;
}

.ion-accordion-toggle-icon {
  margin-right: 0;
}

// Steps
ion-accordion-group.steps {
  margin-right: calc(var(--bkkr-spacer) * -1);
  margin-left: calc(var(--bkkr-spacer) * -1);
  transition: all 300ms cubic-bezier(0.32, 0.72, 0, 1);

  .accordion-readonly::part(header),
  .accordion-readonly::part(content) {
    pointer-events: unset !important;
  }

  /* Spacing */
  .step-header-content,
  .step-header-content-activated,
  .step-content {
    padding-right: var(--bkkr-spacer);
    padding-left: var(--bkkr-spacer);
  }

  // Header
  .step-header {
    position: relative;
    min-height: 30px;

    .step-header-content {
      padding-top: 1px;
      padding-bottom: 1px;
      opacity: 1;
      transition: all 300ms cubic-bezier(0.32, 0.72, 0, 1);
      transform: translateY(0%);
    }

    .step-header-content-activated {
      position: absolute;
      top: 0;
      width: 100%;
      padding-top: 4px;
      padding-bottom: 4px;
      opacity: 0;
      transition: all 300ms cubic-bezier(0.32, 0.72, 0, 1);
      transform: translateY(-100%);
    }
  }

  // Content
  .step-content {
    opacity: 0;
    transition: opacity 300ms cubic-bezier(0.32, 0.72, 0, 1);
  }

  // Expanded header
  .step.accordion-expanding,
  .step.accordion-expanded {
    .step-header-content {
      max-height: 30px;
      pointer-events: none;
      opacity: 0;
      transform: translateY(100%);
    }

    .step-header-content-activated {
      opacity: 1;
      transform: translateY(0%);
    }

    .step-content {
      opacity: 1;
    }
  }
}
