// Back button
ion-back-button {
  --color: var(--bkkr-text-color);
}

// Button
ion-button {
  margin: 0;
  font-weight: 600;
  text-transform: uppercase;

  &.ios.button-solid {
    --background-activated-opacity: 1;
    --background-focused-opacity: 1;
    --background-hover-opacity: 1;
    --border-radius: 12px;
    --box-shadow-color: var(--ion-color-base-rgb, var(--ion-color-primary-rgb));
    --box-shadow: 0 3px 5px 0 rgba(var(--box-shadow-color, 0, 0, 0), 0.1),
      0 6px 10px 0 rgba(var(--box-shadow-color, 0, 0, 0), 0.12),
      0 1.2px 20px 0 rgba(var(--box-shadow-color, 0, 0, 0), 0.08);
    --transition: 0.2s transform cubic-bezier(0.25, 1.11, 0.78, 1.59),
      0.2s box-shadow cubic-bezier(0.25, 1.11, 0.78, 1.59),
      0.2s background-color linear, 0.2s opacity linear;

    &::part(native) {
      transform: translateZ(0);
      will-change: transform, box-shadow;
    }

    &.ion-color {
      --background-activated: var(
        --ion-color-contrast,
        var(--ion-color-primary-contrast)
      );
      --background-focused: var(
        --ion-color-contrast,
        var(--ion-color-primary-contrast)
      );
      --background-hover: var(
        --ion-color-contrast,
        var(--ion-color-primary-contrast)
      );
    }

    &.ion-activated {
      --box-shadow: none;
      --transition: 0.2s transform ease-out, 0.2s box-shadow ease-out,
        0.2s background-color linear, 0.2s opacity linear;

      &::part(native) {
        transform: translate3d(0, 1px, 0);

        &::after {
          background: var(--ion-color-tint) !important;
        }

        @media (prefers-reduced-motion: reduce) {
          transition: none;
          transform: none;
        }
      }
    }
  }

  &.ios.button-clear {
    margin-right: calc(var(--padding-end) * -1);
    margin-left: calc(var(--padding-start) * -1);
  }
}
