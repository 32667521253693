@use "ui-mixins" as mixins;
@use "ui-variables" as variables;

:root {
  // Background and text color defaults
  --bkkr-background-color: #{variables.$background-color-value};
  --bkkr-background-color-rgb: #{variables.$background-color-rgb-value};
  --bkkr-text-color: #{variables.$text-color-value};
  --bkkr-text-color-rgb: #{variables.$text-color-rgb-value};
  --ion-background-color: #{variables.$background-color-value};
  --ion-background-color-rgb: #{variables.$background-color-rgb-value};
  --ion-text-color: #{variables.$text-color-value};
  --ion-text-color-rgb: #{variables.$text-color-rgb-value};

  // Default Foreground and Background Step Colors
  @include mixins.generate-grayscale(variables.$text-color-value, variables.$background-color-value);

  --ion-toolbar-background: var(--ion-background-color);
  --ion-list-inset-background: var(--ion-color-step-50);
}
