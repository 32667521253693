/* devanagari */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/poppins/poppins-ext-normal.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/poppins/poppins-ext-normal.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 400;
  src: url("/assets/fonts/poppins/poppins-normal.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/poppins/poppins-ext-medium.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/poppins/poppins-ext-medium.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  src: url("/assets/fonts/poppins/poppins-medium.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/poppins/poppins-ext-semibold.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/poppins/poppins-ext-semibold.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  src: url("/assets/fonts/poppins/poppins-semibold.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/poppins/poppins-ext-bold.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/poppins/poppins-ext-bold.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 700;
  src: url("/assets/fonts/poppins/poppins-bold.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}

/* devanagari */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/poppins/poppins-ext-black.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0900-097F, U+1CD0-1CF9, U+200C-200D, U+20A8, U+20B9, U+25CC, U+A830-A839, U+A8E0-A8FF;
}

/* latin-ext */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/poppins/poppins-ext-black.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0100-02AF, U+0304, U+0308, U+0329, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}

/* latin */
@font-face {
  font-family: Poppins;
  font-style: normal;
  font-weight: 900;
  src: url("/assets/fonts/poppins/poppins-black.woff2") format('woff2');
  font-display: swap;
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}