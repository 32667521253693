ion-badge {
  --padding-start: 4px;
  --padding-end: 4px;

  min-width: calc(1em + 6px);
  font-size: 0.875rem;
  border-radius: 1em;
}

ion-badge.background-blend.ion-color {
  color: var(--ion-color-base) !important;
  background: var(--background) !important;
}
