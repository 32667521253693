ion-modal.modal-default {
  --height: 100%;
}

ion-modal .ion-page > ion-content::part(scroll) {
  height: 100%;
}

bkkr-modal ion-toolbar {
  --padding-top: calc(var(--bkkr-spacer) * 0.5);
  --padding-start: calc(var(--ion-safe-area-left) + var(--bkkr-spacer) * 0.5);
  --padding-end: calc(var(--ion-safe-area-right) + var(--bkkr-spacer) * 0.5);
  --padding-bottom: calc(var(--bkkr-spacer) * 0.5);
}

:host(.modal-card),
:host(.modal-sheet) {
  --border-radius: 12px;
}

// Drawer types
// -----------------------------------------

.drawer-large {
  --max-height: calc(100% - var(--ion-safe-area-top) - var(--margin-bottom));
  --height: calc(82% - var(--ion-safe-area-top));

  @media screen and (width >= 768px) {
    --max-width: 992px;
  }
}

ion-modal.drawer-large {
  --border-radius: 12px 12px 0 0;
}

// Modal types
// -----------------------------------------

// Viewer
.viewer-modal {
  --min-height: calc(95vh - 80px);
  --min-width: 95vw;
}

// Side
.side-modal {
  --height: 100%;
  --min-height: 100%;
  --max-height: 100%;
  --width: 100%;
  --max-width: 550px !important;
  --border-radius: 0; // 24px 0 0 24px;

  justify-content: end;

  @media screen and (width <= 767px) {
    &.modal-card {
      --width: calc(100% - var(--ion-safe-area-left) - 16px);
    }
  }
}

html bkkr-modal.side-modal ion-header ion-toolbar:first-of-type {
  padding-top: calc(6px + var(--ion-safe-area-top, 0));
}

.side-modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  min-height: 100%;
  padding-right: 16px;
  padding-left: 16px;
}

// Transparent
.transparent-modal {
  --background: transparent;
  --box-shadow: 0;
  --border-radius: 0;
  --backdrop-opacity: 0.8 !important;
}

// Fullscreen
.modal-fullscreen {
  --min-height: 100%;
  --max-height: 100%;
  --width: 100%;
  --border-radius: 0;
}
