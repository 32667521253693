// Toolbar
ion-toolbar:not(.toolbar-title-large, .toolbar-searchbar) {
  --padding-start: calc(var(--ion-safe-area-left) + 1rem);
  --padding-end: calc(var(--ion-safe-area-right) + 1rem);
  --padding-top: 6px;
  --padding-bottom: 6px;
}

// Header
.header-md {
  box-shadow: none;
}

.header-ios.header-collapse-none:not(.header-collapse-main) ion-toolbar {
  --border-width: 0;
}

// Infinite
ion-infinite-scroll.ion-untouched .infinite-scroll-bottom {
  display: none;
}

// Refresher
ion-refresher.refresher-md .refresher-pulling-icon,
ion-refresher.refresher-md .refresher-refreshing-icon {
  background: var(--ion-refresher-background, var(--ion-background-color, #fff));
  border: 0px;
}

// Router outlet
ion-router-outlet {
  background-color: var(--bkkr-background-color);
}
