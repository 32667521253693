ion-searchbar.ios {
  --border-radius: 100px;

  height: 48px;
  min-height: 60px;
}

.searchbar-large {
  &.searchbar-left-aligned.sc-ion-searchbar-ios-h .searchbar-input.sc-ion-searchbar-ios {
    -webkit-padding-start: 40px;
    padding-inline: 40px 48px;
    -webkit-padding-end: 48px;
  }

  .searchbar-input-container.sc-ion-searchbar-ios {
    height: 48px;
  }

  .searchbar-search-icon.sc-ion-searchbar-ios {
    inset-inline-start: 10px;
  }

  .searchbar-clear-button.sc-ion-searchbar-ios {
    width: 48px;
  }
}
