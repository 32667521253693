// Global BKKR properties
// -----------------------------------------

// Button
bkkr-button {
  --background-hover-opacity: 0.08;
  --background-focus-opacity: 0.08;
  --border-radius: 12px;

  text-transform: uppercase;

  &.button-clear:not(.button-block) {
    margin-right: calc(var(--padding-end) * -1);
    margin-left: calc(var(--padding-start) * -1);
  }

  &.button-solid {
    &.bkkr-color.bkkr-disabled {
      --background: var(--bkkr-color-dark);
      --color: var(--bkkr-color-dark-contrast);
      --box-shadow: none;

      opacity: 0.25;
    }
  }

  &.button-solid,
  &.button-outline {
    --height: 3em;

    &:not(.button-has-icon-only) {
      --padding-start: 1.5em;
      --padding-end: 1.5em;
    }

    &.button-small {
      --padding-start: 0.75em;
      --padding-end: 0.75em;
    }
  }

  &.button-large {
    min-width: 300px;
    height: 3.2em;
    font-size: 1.1rem;
  }

  &.button-circle {
    --transform: none;
    --padding-start: 12px;
    --padding-end: 12px;
    --overflow: hidden;
    --box-shadow: none;

    width: 48px;
    height: 48px;

    &.button-solid:not(.bkkr-color-primary),
    &.button-solid:not(.bkkr-color-primary).button-disabled,
    &.button-solid:not(.bkkr-color-primary).bkkr-color.button-disabled {
      --background: rgba(
        var(--ion-color-contrast-rgb, var(--ion-text-color-rgb)),
        0.1
      );
      --color: currentcolor;
    }

    bkkr-icon {
      font-size: 18px !important;
    }
  }

  &.button-circle-small {
    @media screen and (width <= 767px) {
      --padding-start: 10px;
      --padding-end: 10px;

      width: 36px;
      height: 36px;
    }
  }

  &.button-has-icon-only {
    --height: 3em;
  }

  &.button-round {
    --border-radius: 64px;
  }

  &.bkkr-color-light {
    --box-shadow: none;
  }
}

bkkr-button.in-item {
  --padding-start: 0;
  --padding-end: 0;
  --border-radius: 6px;

  width: 35px;
  height: 35px;
  margin: 0;

  bkkr-icon {
    font-size: 1.2rem;
  }
}

.sc-bkkr-buttons-md-s .button-solid {
  --background: var(--bkkr-color-light, #f2f2f2);
  --color: var(--bkkr-color-light-contrast, #000);
}

// Card
bkkr-card {
  --border-radius: 16px;

  &::part(native) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &.card-outline {
    --border-width: 2px;
    --border-color: rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.1);
    --background-hover: rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.1);

    &.bkkr-activatable,
    &.ion-activatable {
      --box-shadow: 0 4px 0 0 rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.15);
      --box-shadow-active: 0 0 0 0
        rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.15);

      overflow: visible;

      &::part(native) {
        transition: 0.2s border cubic-bezier(0.155, 1.105, 0.295, 1.12);
      }

      @media (any-hover: hover) {
        &.bkkr-activatable:hover,
        &.ion-activatable:hover {
          --border-color: rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.5);
          --box-shadow: 0 4px 0 0 rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.5);
          --box-shadow-active: 0 0 0 0
            rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.5);
        }
      }

      &:active {
        --box-shadow: var(--box-shadow-active) !important;
      }
    }
  }

  &.card-solid {
    --background: var(--bkkr-color-step-50);

    @media (any-hover: hover) {
      &.bkkr-activatable:hover {
        --background: var(--bkkr-color-step-100);
      }
    }

    bkkr-label.label-floating {
      --background: var(--bkkr-color-step-50);
    }
  }

  &.bkkr-color {
    --color: var(--bkkr-color-contrast);
  }
}

// Checkbox
bkkr-checkbox {
  --sharp: 2px;
}

// Item
bkkr-item {
  --border-color: var(
    --bkkr-border,
    rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.2)
  );

  line-height: 1.2;

  &.item-fill-none {
    --background: transparent;

    &.bkkr-selected {
      --background: rgba(
        var(--bkkr-text-color-rgb),
        var(--item-background-selected-opacity, 0.04)
      );
      --background-hover-opacity: var(--item-background-hover-opacity, 0.04);
      --background-focused-opacity: var(
        --item-background-focused-opacity,
        0.04
      );
      --background-activated-opacity: var(
        --item-background-activated-opacity,
        0.08
      );
    }

    &:not(.item-input) {
      --inner-padding-top: 8px;
      --inner-padding-bottom: 8px;
      --inner-padding-end: 8px;
      --min-height: 52px;

      overflow: hidden;
    }
  }

  &.item-fill-solid {
    &.bkkr-selected {
      --background: var(--bkkr-color-step-50);
      --background-hover: rgba(var(--bkkr-color-primary-rgb), 0.12);
      --background-focused: rgba(var(--bkkr-color-primary-rgb), 0.12);
      --background-activated: rgba(var(--bkkr-color-primary-rgb), 0.36);
      --color: var(--bkkr-color-primary);
    }
  }

  &.item-fill-outline {
    --border-width: 1px;
    --border-color: var(
      --bkkr-border,
      rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.1)
    );

    /* &:not(.item-input.item-label-floating) {
      overflow: hidden;
    } */

    @media (any-hover: hover) {
      &.item-input:hover {
        --border-color: var(
          --bkkr-border,
          rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.2)
        );
      }
    }

    &.bkkr-selected {
      --border-color: var(--bkkr-color-primary);
    }
  }
}

// List
bkkr-list {
  /* TODO: Migrated, remove later */
  contain: initial;
}

.list-lines-full {
  margin-left: calc(var(--bkkr-spacer) * -1);

  @media screen and (width <= 767px) {
    margin-right: calc(var(--bkkr-spacer) * -1);
  }

  bkkr-item {
    --padding-start: var(--bkkr-spacer);
    --inner-padding-end: var(--bkkr-spacer) !important;
  }
}

bkkr-list.list-inset {
  padding: 0;
  margin-top: 0 !important;
  margin-right: 0 !important;

  /* TODO: Migrated, remove later */
  margin-left: 0 !important;
  border-radius: 12px !important;

  bkkr-item {
    --background: var(
      --bkkr-list-inset-background,
      var(--bkkr-background-color)
    );
  }

  &.list-outline {
    border: 1px solid
      var(--bkkr-border, rgba(var(--bkkr-text-color-rgb, 0, 0, 0), 0.1));

    bkkr-item {
      --background: var(--bkkr-background-color);
    }
  }
}

// Modal
bkkr-modal.modal-card:not(.side-modal) {
  --bkkr-safe-area-top: 0px;

  @media screen and (width >= 768px) {
    --max-width: 992px;
  }
}

// Paginator
.paginator-toolbar bkkr-button.button-clear {
  margin: 0;
}

// Popover
bkkr-popover {
  --border: 0;
  --border-radius: 12px;
}

// Progressbar
ion-progress-bar {
  --progress-background: var(--bkkr-color-base, var(--bkkr-color-primary));
  --buffer-background: var(--bkkr-color-step-50);

  height: 8px;
  border-radius: 8px;
}

// Radio
bkkr-radio {
  margin-right: var(--bkkr-spacer);
  margin-left: 0;
}

// Select
bkkr-select::part(icon) {
  --button-border-radius: 8px;

  width: 35px !important;
  height: 35px !important;
}

// Thumbnail
bkkr-thumbnail {
  --border-radius: 16px;
  --background: var(--bkkr-thumbnail-background, var(--bkkr-color-step-50));
}

bkkr-thumbnail iframe {
  display: block;
}
