@use "sass:map";
@use "sass:color";
@use "sass:math";
@use "ui-functions" as functions;

// Generate color variables

@mixin generate-color($map, $color-name) {
  $value: map.get($map, $color-name);
  $base: map.get($value, base);
  $contrast: map.get($value, contrast);
  $shade: map.get($value, shade);
  $tint: map.get($value, tint);

  --bkkr-color-#{$color-name}: #{$base};
  --bkkr-color-#{$color-name}-rgb: #{functions.to-rgb-list($base)};
  --bkkr-color-#{$color-name}-contrast: #{$contrast};
  --bkkr-color-#{$color-name}-contrast-rgb: #{functions.to-rgb-list($contrast)};
  --bkkr-color-#{$color-name}-shade: #{$shade};
  --bkkr-color-#{$color-name}-shade-rgb: #{functions.to-rgb-list($shade)};
  --bkkr-color-#{$color-name}-tint: #{$tint};
  --bkkr-color-#{$color-name}-tint-rgb: #{functions.to-rgb-list($tint)};
  --ion-color-#{$color-name}: #{$base};
  --ion-color-#{$color-name}-rgb: #{functions.to-rgb-list($base)};
  --ion-color-#{$color-name}-contrast: #{$contrast};
  --ion-color-#{$color-name}-contrast-rgb: #{functions.to-rgb-list($contrast)};
  --ion-color-#{$color-name}-shade: #{$shade};
  --ion-color-#{$color-name}-shade-rgb: #{functions.to-rgb-list($shade)};
  --ion-color-#{$color-name}-tint: #{$tint};
  --ion-color-#{$color-name}-tint-rgb: #{functions.to-rgb-list($tint)};
}

// Generate grayscale steps

@mixin generate-grayscale($text-color, $background-color) {
  $max: 950;
  $step: 50;

  @for $i from 1 through ceil(math.div($max, $step)) {
    $value: $i * $step;

    --bkkr-color-step-#{$value}: #{color.mix($text-color, $background-color, 0% + ($value * 0.1))};
    --ion-color-step-#{$value}: #{color.mix($text-color, $background-color, 0% + ($value * 0.1))};
  }
}

// Generate color classes

@mixin classes($map, $color-name) {
  $value: map.get($map, $color-name);
  $base: map.get($value, base);
  $contrast: map.get($value, contrast);
  $shade: map.get($value, shade);
  $tint: map.get($value, tint);

  .color-#{$color-name},
  .bkkr-color-#{$color-name},
  .ion-color-#{$color-name} {
    --bkkr-color-base: var(--bkkr-color-#{$color-name}, #{$base});
    --bkkr-color-base-rgb: var(--bkkr-color-#{$color-name}-rgb, #{functions.to-rgb-list($base)});
    --bkkr-color-contrast: var(--bkkr-color-#{$color-name}-contrast, #{$contrast});
    --bkkr-color-contrast-rgb: var(--bkkr-color-#{$color-name}-contrast-rgb, #{functions.to-rgb-list($contrast)});
    --bkkr-color-shade: var(--bkkr-color-#{$color-name}-shade, #{$shade});
    --bkkr-color-shade-rgb: var(--bkkr-color-#{$color-name}-shade-rgb, #{functions.to-rgb-list($shade)});
    --bkkr-color-tint: var(--bkkr-color-#{$color-name}-tint, #{$tint});
    --bkkr-color-tint-rgb: var(--bkkr-color-#{$color-name}-tint-rgb, #{functions.to-rgb-list($tint)});
    --ion-color-base: var(--ion-color-#{$color-name}, #{$base});
    --ion-color-base-rgb: var(--ion-color-#{$color-name}-rgb, #{functions.to-rgb-list($base)});
    --ion-color-contrast: var(--ion-color-#{$color-name}-contrast, #{$contrast});
    --ion-color-contrast-rgb: var(--ion-color-#{$color-name}-contrast-rgb, #{functions.to-rgb-list($contrast)});
    --ion-color-shade: var(--ion-color-#{$color-name}-shade, #{$shade});
    --ion-color-shade-rgb: var(--ion-color-#{$color-name}-shade-rgb, #{functions.to-rgb-list($shade)});
    --ion-color-tint: var(--ion-color-#{$color-name}-tint, #{$tint});
    --ion-color-tint-rgb: var(--ion-color-#{$color-name}-tint-rgb, #{functions.to-rgb-list($tint)});
  }
}

// Generate text classes

@mixin text($map, $color-name) {
  $value: map.get($map, $color-name);
  $base: map.get($value, base);

  .text-#{$color-name} {
    --color: #{$base};

    color: var(--color) !important;
  }
}
