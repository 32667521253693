ion-row {
  --ion-grid-column-padding: 10px;

  margin-right: calc(var(--ion-grid-column-padding, 5px) * -1);
  margin-left: calc(var(--ion-grid-column-padding, 5px) * -1);
}

ion-col {
  padding-top: 0;
  padding-bottom: 0;
}
