ion-datetime-button::part(native) {
  background: var(--ion-color-step-50, #edeef0);

  @media (any-hover: hover) {
    &:hover {
      background: var(--ion-color-step-100, #edeef0);
    }
  }
}

ion-datetime {
  --background: inherit;
}
