.reorder-enabled {
  --opacity-selected: 1;
}

ion-reorder-group > .reorder-selected:not(ion-item) {
  box-shadow: none;

  & > ion-reorder {
    border-radius: var(--border-radius);
    box-shadow: var(--box-shadow-selected);
    transition: 0.3s box-shadow var(--ease-cubic) !important;
  }
}